<template>
  <v-btn-toggle v-model="view" mandatory>
    <v-btn value="list" :style="expanded ? 'width: 85px' : ''">
      <v-icon>fal fa-list</v-icon>
    </v-btn>
    <v-btn value="card" :style="expanded ? 'width: 85px' : ''">
      <v-icon>fal fa-id-card</v-icon>
    </v-btn>
    <v-btn value="photo" :style="expanded ? 'width: 85px' : ''">
      <v-icon>fal fa-portrait</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>
<script>
import { computed } from '@vue/composition-api'
export default {
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root }) {
    const view = computed({
      get: () => root.$store.state.greatscots.listView,
      set: (val) => root.$store.commit('greatscots/setView', val)
    })

    return {
      view
    }
  }
}
</script>
